class FieldWithIncrementButton extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.addEventListener('click', clickEvent => {
      const btn = clickEvent.target.closest('button');

      if (btn) {
        this.increment()
        event.preventDefault();
      }
    }, true);

    this.addEventListener('keydown', keyEvent => {
      const inputElement = keyEvent.target;

      if (keyEvent.defaultPrevented) {
        return false;
      } else if (event.key == 'ArrowUp') {
        event.preventDefault();
        this.increment();
      } else if (event.key == 'ArrowDown') {
        event.preventDefault();
        this.decrement();
      }
    }, true);
  }

  get inputElement() {
    return this.querySelector('input[type=text]');
  }

  increment() {
    if ('' + this.inputElement.value === '') {
      this.inputElement.value = 1;
    } else {
      this.inputElement.value = 1 + parseInt(this.inputElement.value);
    }
  }

  decrement() {
    if ('' + this.inputElement.value !== '') {
      this.inputElement.value = parseInt(this.inputElement.value) - 1;
    }
    if (this.inputElement.value == 0) {
      this.inputElement.value = '';
    }
  }
}

class SwitchButton extends HTMLElement {
  constructor() {
    super();
  }

  get button() {
    return this.querySelector('button');
  }

  invoke() {
    this.button.click()
  }
}

class SwitchPreviousButton extends SwitchButton {
  constructor() {
    super();
  }

  connectedCallback() {
    document.addEventListener('amphis:previous', this.invoke.bind(this))
    this.addEventListener('click', function(ev) {
      document.querySelector('amphis-subsite').classList.add('slide-left');
    });
  }
}

class SwitchNextButton extends SwitchButton {
  constructor() {
    super();
  }

  connectedCallback() {
    document.addEventListener('amphis:next', this.invoke.bind(this));
    this.addEventListener('click', function(ev) {
      document.querySelector('amphis-subsite').classList.add('slide-right');
    });
  }
}

class AmphisSubsite extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.addEventListener('keypress', event => {
      if (event.defaultPrevented) {
        return false;
      } else if (event.shiftKey && event.key == 'Enter') {
        event.preventDefault();
        event.target.dispatchEvent(new CustomEvent('amphis:previous', {bubbles: true}));
      } else if (event.key == 'Enter') {
        event.preventDefault();
        event.target.dispatchEvent(new CustomEvent('amphis:next', {bubbles: true}));
      }
    }, true);
  }
}

class NewSpecies extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    const button = this.querySelector('button');
    button.disabled = true;

    this.addEventListener('change', ev => {
      if (ev.target.value) {
        button.disabled = false;
      } else {
        button.disabled = true;
      }
    }, true);
  }
}

customElements.define('new-species', NewSpecies);
customElements.define('amphis-subsite', AmphisSubsite);
customElements.define('field-with-increment-button', FieldWithIncrementButton);
customElements.define('switch-previous-button', SwitchPreviousButton);
customElements.define('switch-next-button', SwitchNextButton);
