import SimpleMDE from 'simplemde-w';

class MarkdownEditor extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    window.setTimeout(this.init.bind(this), 10);
  }

  disconnectedCallback() {
    this.simplemde.toTextArea();
    this.simplemde = null
  }

  init() {
    const opts = {
      element: this.querySelector('textarea'),
      toolbar: [
        "bold", "italic", "heading", "strikethrough", "|",
        "quote", "unordered-list", "ordered-list", "table", "|",
        "link", "image", "preview", "|", "guide"
      ],
      insertTexts: {
        horizontalRule: ["", "\n\n-----\n\n"],
        image: ["![](https://", ")"],
        link: ["[", "](https://)"],
        table: ["", "\n\n| Spalte 1 | Spalte 2 | Spalte 3 |\n| -------- | -------- | -------- |\n| Text     | Text      | Text     |\n\n"],
      },
      autosave: {
        enabled: false,
      },
      status: false,
    };
    this.simplemde = new SimpleMDE(opts);
    this.simplemde.render();

    if (this.hasAttribute('lines')) {
      const minHeight = `${1.5 * this.getAttribute('lines')}em`;
      this.querySelectorAll('.CodeMirror,.CodeMirror-scroll').forEach(elem => {
        elem.style.minHeight = minHeight;
      });
    }
  }
}

customElements.define('markdown-editor', MarkdownEditor);
