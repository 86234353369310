import {List} from 'list.js';

class SortableTable extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    window.setTimeout(this.connect.bind(this), 0);
  }

  connect() {
    if (!this.querySelector('.list')) {
      console.log('<sortable-table>: You must specify an element (tbody?) with class "list"', this);
    }

    let options = {
      valueNames: []
    };

    this.querySelectorAll('thead > tr [data-sort]').forEach(function(el) {
      el.classList.add('sort');
      if (el.getAttribute('data-sort-attr')) {
        options.valueNames.push({name: el.getAttribute('data-sort'), attr: el.getAttribute('data-sort-attr')});
      } else {
        options.valueNames.push(el.getAttribute('data-sort'));
      }
    });

    let list = new List(this, options);
    const defaultSortFunction = list.sortFunction;
    list.sortFunction = function(a, b, opts) {
      if (a.values()[opts.valueName] === '-by-value-') {
        const sel = `.${opts.valueName}`;
        return list.utils.naturalSort(a.elm.querySelector(sel).value, b.elm.querySelector(sel).value, opts);
      } else {
        return defaultSortFunction(a, b, opts);
      }
    };

    list.on('sortComplete', function(list) {
      const sortColumn = list.list.parentNode.querySelector('.asc, .desc');
      if (sortColumn) {
        const sortDirection = sortColumn.classList.contains('desc') ? 'desc' : 'asc';
        const sortColumnName = sortColumn.dataset.sort;
      }
      let cookiesAreOK = false;
      if (!Cookies.get('cookiesAllowed')) {
        if (window.confirm('Darf ich Ihnen einen Keks anbieten? Wenn Sie Cookies zulassen, kann ich die Sortier-Reihenfolge der Tabelle speichern, sonst nicht.')) {
          Cookies.set('cookiesAllowed', 'YES');
          cookiesAreOK = true;
        } else if (window.confirm('Damit diese vorherige Frage nicht ständig wieder nervt, kann ich Ihre Antwort als Cookie speichern. Wäre dieses eine Cookie OK?')) {
          Cookies.set('cookiesAllowed', 'NO');
        }
      }

      if (sortColumnName && cookiesAreOK) {
        Cookies.set('tableSortColumn', sortColumnName);
        Cookies.set('tableSortDirection', sortDirection);
      }

      if (this.querySelectorAll('.sort.asc.default-asc').count + this.querySelectorAll('.sort.desc.default-desc').count > 0) {
        this.classList.add('default-order');
      } else {
        this.classList.remove('default-order');
      }
    });

    if (Cookies.get('tableSortColumn')) {
      list.sort(Cookies.get('tableSortColumn'), {
        order: Cookies.get('tableSortDirection') || 'asc'
      });
    }
  }
}

if (List !== undefined) {
  customElements.define('sortable-table', SortableTable);
}
