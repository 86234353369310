class ShownOrHiddenIfChecked extends HTMLElement {
  constructor() {
    super();
  }

  get targetElement() {
    return document.querySelector(this.getAttribute('checkbox'));
  }

  connectedCallback() {
    window.setTimeout(this.connect.bind(this), 0);
  }

  connect() {
    this.classList.add('hidden-if');

    if (this.targetElement) {
      this.toggle();
      this.targetElement.addEventListener('change', this.toggle.bind(this));
    }
  }

  toggle() {
    if (this.showConditionMet) {
      this.show();
    } else {
      this.hide();
    }
  }

  show() {
    this.setAttribute('aria-hidden', false);
    this.removeAttribute('hidden');
    this.classList.remove('hidden-if-hidden');
  }

  hide() {
    this.setAttribute('aria-hidden', true);
    this.setAttribute('hidden', true);
    this.classList.remove('hidden-if-hidden');
  }
}

class ShownIfChecked extends ShownOrHiddenIfChecked {
  get showConditionMet() {
    return this.targetElement.checked
  }
}

class HiddenIfChecked extends ShownOrHiddenIfChecked {
  get showConditionMet() {
    return !this.targetElement.checked
  }
}

customElements.define('shown-if-checked', ShownIfChecked);
customElements.define('hidden-if-checked', HiddenIfChecked);
