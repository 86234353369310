import Map from 'ol/Map.js';
import OSM from 'ol/source/OSM.js';
import VectorSource from 'ol/source/Vector.js';
import GPX from 'ol/format/GPX.js';
import Overlay from 'ol/Overlay.js';
import View from 'ol/View.js';
import {boundingExtent} from 'ol/extent.js';
import {fromLonLat, transform, get as getProj, transformExtent} from 'ol/proj.js';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer.js';
import {Fill, Stroke, Style, Circle, Icon} from 'ol/style.js';
import Feature from 'ol/Feature.js';
import {Point, Polygon} from 'ol/geom.js';


const mapStyle = {
  'Point': new Style({
    image: new Circle({
      fill: new Fill({
        color: 'rgba(255,255,0,0.4)'
      }),
      radius: 5,
      stroke: new Stroke({
        color: '#ff0',
        width: 1
      })
    })
  }),
  'LineString': new Style({
    stroke: new Stroke({
      color: '#f00',
      width: 3
    })
  }),
  'MultiLineString': new Style({
    stroke: new Stroke({
      color: '#0f0',
      width: 3
    })
  }),
  'normal': new Style({
    fill: new Fill({
      color: 'rgba(34, 34, 240, 0.1)'
    }),
    stroke: new Stroke({
      color: '#101071',
      width: 1
    }),
    image: new Circle({
      radius: 7,
      fill: new Fill({
        color: '#ffcc33'
      })
    })
  }),
  'highlight': new Style({
    stroke: new Stroke({
      color: '#2222f0',
      width: 3
    }),
    fill:   new Fill({
      color: 'rgba(34, 34, 240, 0.2)'
    })
  })
};

// Event bus wrapper for map box and coords pickers
export class MapBoxWithCoordsPickers extends HTMLElement {
  constructor() {
    super();
  }
}

// Wrapper for Lon and Lat input fields. Updates Picker position on change.
export class CoordsPicker extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.eventBus = this.closest('[eventbus]') || this;
    this.eventBus.addEventListener('mapbox:pick', this.setCoords.bind(this));
    this.addEventListener('change', this.dispatchCoordsChanged.bind(this));
    this.dispatchEvent(new CustomEvent('picker:setup', {bubbles: true}));
  }

  disconnectedCallback() {
    if (this.eventBus) {
      this.eventBus.removeEventListener('mapbox:pick', this.setCoords.bind(this));
      this.removeEventListener('change', this.dispatchCoordsChanged.bind(this));
      this.dispatchEvent(new CustomEvent('picker:teardown', {bubbles: true}));
    }

    this.eventBus = undefined;
  }

  setCoords(event) {
    // if (event.detail.id == this.getAttribute('id')) {
      this.coords = event.detail.coords;
    // }
  }

  get lonInput() {
    return this.querySelector('input.lon');
  }

  get latInput() {
    return this.querySelector('input.lat');
  }

  get mapBox() {
    return this.querySelector('map-box');
  }

  get lon() {
    if (this.lonInput.value === '') {
      return parseFloat(this.lonInput.dataset.default);
    } else {
      return this.lonInput.value;
    }
  }

  set lon(value) {
    const step = (parseFloat(this.lonInput.step) || 0.00005);
    this.lonInput.value = Math.round(value / step) * step;
    this.lonInput.dispatchEvent(new Event('change', {bubbles: true}));
  }

  get lat() {
    if (this.latInput.value === '') {
      return parseFloat(this.latInput.dataset.default);
    } else {
      return this.latInput.value;
    }
  }

  set lat(value) {
    const step = (parseFloat(this.latInput.step) || 0.00005);
    this.latInput.value = Math.round(value / step) * step;
    this.latInput.dispatchEvent(new Event('change', {bubbles: true}));
  }

  get coords() {
    return [this.lon, this.lat];
  }

  set coords(c) {
    this.lon = c[0];
    this.lat = c[1];
  }

  get pos() {
    return fromLonLat(this.coords);
  }

  dispatchCoordsChanged(event) {
    const evt = new CustomEvent('picker:coords-changed', {
      bubbles: true,
      cancelable: false,
      detail: {
        coords: this.coords
      }
    });
    this.dispatchEvent(evt);
  }
}


class BBoxedElement extends HTMLElement {
  constructor() {
    super();
  }

  get boundsEast() {
    if (this.hasAttribute('bounds-east')) {
      return parseFloat(this.getAttribute('bounds-east'));
    }
  }

  get boundsWest() {
    if (this.hasAttribute('bounds-west')) {
      return parseFloat(this.getAttribute('bounds-west'));
    }
  }

  get boundsNorth() {
    if (this.hasAttribute('bounds-north')) {
      return parseFloat(this.getAttribute('bounds-north'));
    }
  }

  get boundsSouth() {
    if (this.hasAttribute('bounds-south')) {
      return parseFloat(this.getAttribute('bounds-south'));
    }
  }

  get bbox() {
    if (this.boundsNorth && this.boundsEast && this.boundsSouth && this.boundsWest) {
      return [[this.boundsWest, this.boundsNorth], [this.boundsEast, this.boundsSouth]];
    }
  }
}

class GeoNameMarker extends BBoxedElement {
  constructor() {
    super();
  }

  connectedCallback() {
    // this.addEventListener('mapbox:pick', this.setCoords.bind(this));
    // this.addEventListener('change', this.setPickerPos.bind(this));
        // let geonameIdSelector = document.querySelector('input[value="' + geonameIdSelectors[i].getAttribute('geoname-id') + '"]');
        //
        // geonameIdSelector.addEventListener('change', evt => {
        //   this.vectorSource.forEachFeature(e => e.setStyle(null) );
        //   let feature = this.vectorSource.getFeatureById(evt.target.value);
        //   feature.setStyle(highlightStyle);
        // }, true);
  }
}

class SiteMarker extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.classList.remove('hidden');
    this.classList.add('overlay');

    const evt = new CustomEvent('site-marker:connected', {
      bubbles: true,
      cancelable: false,
    });
    this.dispatchEvent(evt);
  }

  toOverlay() {
    return new Overlay({
      position:    this.pos,
      positioning: 'center-center',
      element:     this,
      stopEvent:   false
    });
  }

  get coords() {
    return [this.lon, this.lat];
  }

  get pos() {
    return fromLonLat(this.coords);
  }

  get lon() {
    if (this.hasAttribute('lon')) {
      return parseFloat(this.getAttribute('lon'));
    }
  }

  get lat() {
    if (this.hasAttribute('lat')) {
      return parseFloat(this.getAttribute('lat'));
    }
  }

}

class SitePicker extends SiteMarker {
  constructor() {
    super();
  }

  connectedCallback() {
    this.eventBus = this.closest('[eventbus]');
    this.eventBus.addEventListener('picker:coords-changed', this.setPos.bind(this));
    this.eventBus.addEventListener('mapbox:pick', this.setPos.bind(this));

    this.feature = new Feature({
      geometry: new Point(this.pos),
    });
    const evt = new CustomEvent('site-picker:connected', {
      bubbles: true,
      cancelable: false
    });
    this.dispatchEvent(evt);
  }

  disconnectedCallback() {
    this.eventBus.removeEventListener('picker:coords-changed', this.setPos.bind(this));
    this.eventBus.removeEventListener('mapbox:pick', this.setPos.bind(this));
    this.eventBus = undefined;
  }

  setPos(evt) {
    this.setAttribute('lon', evt.detail.coords[0]);
    this.setAttribute('lat', evt.detail.coords[1]);
    this.feature.getGeometry().setCoordinates(this.pos);
  }

  get img() {
    return this.getAttribute('img');
  }

  toLayer() {
    const style = new Style({
      image: new Icon({
        src:    this.img,
        anchor: [0.5, 1],
      })
    });
    const source = new VectorSource();
    source.addFeature(this.feature);

    return new VectorLayer({
      source: source,
      style:  style,
    });
  }
}

class MapBox extends BBoxedElement {
  constructor() {
    super();
    this.setupComplete = false;
  }

  connectedCallback() {
    if (!this.setupComplete && this.isConnected) {
      this.eventBus = this.closest('[eventbus]') || this;
      this.eventBus.addEventListener('site-marker:connected', this.handleMarkerConnected.bind(this));
      this.eventBus.addEventListener('site-picker:connected', this.handlePickerConnected.bind(this));

      const shadow = this.attachShadow({mode: 'open'});
      this.target = document.createElement('div');
      this.target.style.width     = '100%';
      this.target.style.height    = '350px';
      this.target.style.minHeight = '350px';
      shadow.appendChild(this.target);

      this.setupStyle(shadow);
      this.setup();
    } else {
      console.log(this.setupComplete, this.isConnected)
    }
  }

  disconnectedCallback() {
    this.eventBus.removeEventListener('site-marker:connected', this.handleMarkerConnected.bind(this));
    this.eventBus.removeEventListener('site-picker:connected', this.handlePickerConnected.bind(this));
    this.map.un('click', this.dispatchPickEvent.bind(this));
  }

  setupStyle(shadow) {
    const style = document.createElement('style');

    style.textContent = `
      site-marker a {
        border: 1px solid #ddd;
        border-radius: 40px;
        overflow: hidden;
        padding: 4px;
        color: #fff !important;
        display: block;
      }

      site-marker a img {
        margin: auto;
        width: 32px;
        height: 32px;
      }

      @keyframes pulse {
        to {
          box-shadow: 0 0 0 50px rgba(118, 184, 40, 0);
        }
      }

      site-marker a.active {
        border: 2px solid #0a0;
        box-shadow: 0 0 0 0 rgba(118, 184, 40, 0.7);
        cursor: pointer;
        background-color: rgba(118, 184, 40, 0.7);
      }
      site-marker a.active.activity-0 {
        animation: pulse 3s infinite cubic-bezier(.55,.06,.68,.19);
      }
      site-marker a.active.activity-1 {
        animation: pulse 2s infinite cubic-bezier(.55,.06,.68,.19);
      }
      site-marker a.active.activity-2 {
        animation: pulse 1.5s infinite cubic-bezier(.55,.06,.68,.19);
      }
      site-marker a.active.activity-3 {
        animation: pulse 1s infinite cubic-bezier(.55,.06,.68,.19);
      }
      site-marker a.active.activity-4 {
        animation: pulse 0.7s infinite cubic-bezier(.55,.06,.68,.19);
      }

      site-marker a.inactive {
        border: 2px solid #966;
        background-color: #cbb;
        box-shadow: 0 0 5px #666;
      }

      site-marker a.inactive img {
        opacity: 0.6;
      }

      .popover-content {
        min-width: 180px;
      }
    `;

    shadow.appendChild(style);
  }

  setup() {
    this.vectorSource = new VectorSource();
    this.vectorLayer  = new VectorLayer({
      source: this.vectorSource,
      style:  mapStyle['normal']
    });

    this.map = new Map({
      controls: [],
      layers: [
        new TileLayer({ source: new OSM() }),
        this.vectorLayer
      ],
      target: this.target,
      view:   new View({
        center: fromLonLat([9, 51]),
        zoom: 6
      })
    });

    if (this.gpxURL) {
      this.gpxLayer = new VectorLayer({
        source: new VectorSource({
          url:    this.gpxURL,
          format: new GPX()
        }),
        style: (feature, resolution) => mapStyle[feature.getGeometry().getType()]
      });
      this.map.addLayer(this.gpxLayer);
    }

    if (this.centerCoords) {
      this.centerOn(this.centerCoords, 13);
    } else if (this.bbox) {
      this.fitToBBox(this.bbox);
    }

    // const geonameMarkers = document.querySelectorAll('geo-name-marker');
    // if (geonameMarkers.length > 0) {
    //   geonameMarkers.forEach(boxElement => {
    //     const geometry = new Polygon.fromExtent(boxElement.bbox);
    //     const feature  = new Feature({
    //       geometry: geometry.transform('EPSG:4326', this.map.getView().getProjection())
    //     });
    //     this.vectorSource.addFeature(feature);
    //   });
    // }

    this.map.on('click', this.dispatchPickEvent.bind(this));
    this.setupComplete = true;
  }

  handleMarkerConnected(e) {
    this.map.addOverlay(e.target.toOverlay());
  }

  handlePickerConnected(e) {
    const picker = e.target;
    this.map.addLayer(picker.toLayer());
  }

  dispatchPickEvent(clickEvent) {
    const evt = new CustomEvent('mapbox:pick', {
      bubbles: true,
      cancelable: false,
      detail: {
        coords: transform(clickEvent.coordinate, 'EPSG:3857', 'EPSG:4326')
      }
    });
    this.dispatchEvent(evt);
  }

  setPickerPos(val) {
    this.picker.setAttribute('lon', val[0]);
    this.picker.setAttribute('lat', val[1]);
    this.picker.feature.getGeometry().setCoordinates(this.picker.pos);

    this.centerOn(this.picker.coords, this.map.getView().getZoom());
  }

  get gpxURL() {
    return this.getAttribute('gpx-url');
  }

  get centerLon() {
    if (this.hasAttribute('center-lon')) {
      return parseFloat(this.getAttribute('center-lon'));
    }
  }

  get centerLat() {
    if (this.hasAttribute('center-lat')) {
      return parseFloat(this.getAttribute('center-lat'));
    }
  }

  get centerCoords() {
    if (this.centerLon && this.centerLat) {
      return [this.centerLon, this.centerLat];
    }
  }

  addFeature(feature) {
    this.vectorSource.addFeature(feature);
  }

  fitToBBox(bbox) {
    let ext = boundingExtent(bbox);
    let box = transformExtent(ext, getProj('EPSG:4326'), getProj('EPSG:3857'));
    this.map.getView().fit(box, this.map.getSize());
  }

  centerOn(coords, zoomlevel) {
    this.map.setView(new View({
      center: transform(coords, 'EPSG:4326', 'EPSG:3857'),
      zoom:   zoomlevel
    }));
  }
}

customElements.define('map-box', MapBox);
customElements.define('site-marker', SiteMarker);
customElements.define('site-picker', SitePicker);
customElements.define('coords-picker', CoordsPicker);
customElements.define('geo-name-marker', GeoNameMarker);

