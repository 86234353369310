import { Controller } from "@hotwired/stimulus"
import Cookies        from "js-cookie"
import * as Turbo     from "@hotwired/turbo"

export default class extends Controller {
  connect() {
    this.element.querySelectorAll('.sort').forEach(th => {
      const a = th.querySelector('a[href]');
      const href = a.getAttribute('href');
      a.classList.add('unlink');
      th.addEventListener('click', e => {
        e.preventDefault();
        this.setCookie(th.dataset.sort, th.dataset.dir);
        Turbo.visit(href);
      });
    });
  }

  setCookie(sortColumnName, sortDirection) {
    const cookieOpts = {sameSite: 'strict'};

    let cookiesAreOK = false;
    switch (Cookies.get('cookiesAllowed')) {
      case 'YES':
        cookiesAreOK = true;
        break;
      case 'NO':
        break;
      default:
        if (window.confirm('Darf ich Ihnen einen Keks anbieten? Wenn Sie Cookies zulassen, kann ich die Sortier-Reihenfolge der Tabelle speichern, sonst nicht.')) {
          Cookies.set('cookiesAllowed', 'YES', cookieOpts);
          cookiesAreOK = true;
        } else if (window.confirm('Damit diese vorherige Frage nicht ständig wieder nervt, kann ich Ihre Antwort als Cookie speichern. Wäre dieses eine Cookie OK?')) {
          Cookies.set('cookiesAllowed', 'NO', cookieOpts);
        }
    }

    if (!!this.element.dataset.cookiePath) {
      cookieOpts['path'] = this.element.dataset.cookiePath;
    }

    if (cookiesAreOK && sortColumnName && sortDirection) {
      Cookies.set('tableSort', `${sortColumnName}:${sortDirection}`, cookieOpts);
    }
  }
}
