class CloseBtn extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    const target = this.closest('turbo-frame.closeable');
    if (target) {
      const shadow = this.attachShadow({mode: 'open'});
      this.content = document.createElement('span');
      shadow.appendChild(this.content);
      this.content.style.padding = '0.5rem 1rem';
      this.content.style.border = '1px solid var(--dark-grey)';
      this.content.style.float  = 'right';
      this.content.style.backgroundColor= 'var(--light-blue)';
      this.content.style.color = 'var(--white)';
      this.content.style.cursor = 'pointer';
      this.content.innerText = 'schließen';

      const self = this;
      const transitionEndListener = function(e) {
        target.removeEventListener('transitionend', transitionEndListener, true);
        target.classList.remove('closing');
        target.innerHTML = '';
      }
      const clickListener = function(e) {
        target.classList.add('closing');
        target.addEventListener('transitionend', transitionEndListener, true);
        self.removeEventListener('click', clickListener);
      }

      this.addEventListener('click', clickListener);
    }
  }
}

customElements.define('close-btn', CloseBtn);
