import SlimSelect from './slimselect.min';

class SlimSelectElement extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    window.setTimeout(this.connect.bind(this), 0);
  }

  connect() {
    let selectElement = this.querySelector('select');
    new SlimSelect({
      select: selectElement,
      placeholder: (selectElement.getAttribute('placeholder') || 'Suche...'),
      allowDeselect: true,
      searchHighlight: true,
      searchText: 'Nix passendes.'
    });
  }
}

customElements.define('slim-select', SlimSelectElement);
