class PlannableCell extends HTMLElement {
  constructor() {
    super();
  }

  // connectedCallback() {
  // }
}

customElements.define('plannable-cell', PlannableCell);
