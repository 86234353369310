// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import "./components"

// hack to view pages with broken/absent turbo response instead of "content missing"
document.addEventListener("turbo:frame-missing", event => {
  event.preventDefault()
  event.detail.visit(event.detail.response)
})
