import { Controller } from "@hotwired/stimulus"

let debounceTimer;
function debounce(callback, time) {
  return function() {
    window.clearTimeout(debounceTimer);
    debounceTimer = window.setTimeout(callback, time);
  }
};

export default class extends Controller {
  static targets = ['results'];

  connect() {
    this.element.addEventListener('input', debounce(this.element.requestSubmit.bind(this.element), 333), false);
  }
}
