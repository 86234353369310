import { Chart } from "chart.js/auto";

const tpl = document.createElement('template');

tpl.innerHTML = `
  <slot></slot>
  <div class="observations-container">
    <div class="donut-inner">
      <h5 class="quantity-sum"></h5>
      <small>Individuen</small>
    </div>

    <canvas class width="200" height="200"></canvas>
  </div>

  <style>
    .observations-container {
      width: 100%;
      margin: auto;
      max-width: 300px;
      position: relative;
    }
    canvas {
      position: absolute;
      top: 0;
      left: 0;
      max-height: 100%;
      max-width: 100%;
      margin: inherit auto;
    }
    .donut-inner {
      padding-top: 40%;
      height: 250px;
      display: block;
      text-align: center;
    }
    .quantity-sum {
      font-size: 1.6rem;
      margin: 0 auto;
    }
  </style>
`;

class ObservationsDiagram extends HTMLElement {
  constructor() {
    super();
    this.datasetSelector  = this.dataset.datasetSelector  || 'table .dataset';
    this.speciesSelector  = this.dataset.speciesSelector  || '.species';
    this.quantitySelector = this.dataset.quantitySelector || '.quantity';

    this.chartData = {
      labels: [],
      datasets: [{
        data: [],
        backgroundColor: [],
        hoverBorderColor: [],
        hoverBackgroundColor: []
      }]
    };

    this.shadow = this.attachShadow({mode: 'open'});
    this.shadow.appendChild(tpl.content.cloneNode(true));
  }

  connectedCallback() {
    window.setTimeout(this.connect.bind(this), 10);
  }

  connect() {
    let quantitySum = 0;

    this.canvas = this.shadow.querySelector('canvas');

    this.querySelectorAll(this.datasetSelector).forEach((dataSet, n) => {
      let graphColour  = dataSet.dataset.graphColour;
      let speciesElem  = dataSet.querySelector(this.speciesSelector);
      let quantityElem = dataSet.querySelector(this.quantitySelector);

      this.chartData.datasets[0].backgroundColor.push(`rgba(${graphColour},0.6)`);
      this.chartData.datasets[0].hoverBorderColor.push(`rgba(${graphColour},0.9)`);
      this.chartData.datasets[0].hoverBackgroundColor.push(`rgba(${graphColour},0.9)`);

      quantityElem.style.backgroundColor = `rgba(${graphColour},0.9)`;
      quantityElem.style.color      = '#fff';
      quantityElem.style.fontWeight = 'bold';
      quantityElem.style.textAlign  = 'center';
      this.chartData.labels[n]      = speciesElem.innerText;
      this.chartData.datasets[0].data[n] = parseInt(quantityElem.innerText);
      quantitySum += this.chartData.datasets[0].data[n];
    });

    this.shadow.querySelector('.quantity-sum').innerText = quantitySum;

    new Chart(this.canvas.getContext('2d'), {
      type: 'doughnut',
      data: this.chartData,
      options: {
        cutout: '60%',
        animation: {
          animateRotate: false
        },
        plugins: {
          legend: {
            display: false
          }
        }
      }

    });
  }
}

customElements.define('observations-diagram', ObservationsDiagram);
