import Sortable from 'sortablejs';
import { put } from '@rails/request.js'


export class DndSortableList extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    window.requestAnimationFrame(this.init.bind(this));
  }

  disconnectedCallback() {
    this.sortable.destroy();
  }

  init() {
    this.sortable = Sortable.create(this, {
      draggable: 'turbo-frame',
      dataIdAttr: 'id',
      handle: '.handle',
      store: {
        set: this.putOrderToServer.bind(this),
      }
    });
  }

  async putOrderToServer(sortable) {
    const order = sortable.toArray();
    console.log(this.dataset.updateUrl)
    const response = await put(this.dataset.updateUrl, { body: JSON.stringify(order) });
      console.log(response)
    if (response.ok) {
    } else {

    }
  }

}

customElements.define('dnd-sortable-list', DndSortableList);
