import { Chart } from "chart.js/auto";

class YearlyActivity extends HTMLElement {
  constructor() {
    super();

    this.xAxisSelector   = this.dataset.xAxisSelector   || '.season';
    this.yAxisSelector   = this.dataset.yAxisSelector   || '.species';
    this.zAxisSelector   = this.dataset.zAxisSelector   || '.quantity';
    this.datasetSelector = this.dataset.datasetSelector || '.dataset';
    this.canvasSelector  = this.dataset.canvasSelector  || 'canvas';

    this.chartOptions = {
      // FIXME: v4 structure
      scaleBeginAtZero: true,
      scaleShowGridLines: true,
      scaleFontSize: 10,
      scaleGridLineColor: 'rgba(0,0,0,0.05)',
      scaleGridLineWidth: 1,
      pointDot: true,
      responsive: true,
      maintainAspectRatio: false,
      bezierCurve: false,
      tooltipFontSize: 10,
      tooltipTitleFontSize: 10,
      showTooltips: true,
      multiTooltipTemplate: '<%= datasetLabel %>: <%= value %>'
    };
  }

  connectedCallback() {
    window.setTimeout(this.connect.bind(this), 0);
  }

  connect() {
    let canvas = this.querySelector(this.canvasSelector);
    if (canvas) {
      if (this.chart) {
        this.chart.destroy();
      }

      let chartLabels = [];
      let seasonNames = [];
      let colours     = [];
      let speciesRawData = {};
      let chartData = {
        labels:   [],
        datasets: []
      };

      window.setTimeout(_ => {
        this.seasonElements.forEach((seasonElem, s_idx) => {
          let xAxisName = seasonElem.querySelector(this.xAxisSelector).innerText.replace(/^\s+|\s+$/g, '');
          seasonNames.push(xAxisName);

          seasonElem.querySelectorAll(this.datasetSelector).forEach((elem, idx) => {
            let speciesName = elem.querySelector(this.yAxisSelector).innerText.replace(/^\s+|\s+$/g, '');

            colours[speciesName] = elem.dataset.graphColour;

            let observationQuantity = parseInt(elem.querySelector(this.zAxisSelector).innerText);

            if (!speciesRawData[speciesName]) {
              speciesRawData[speciesName] = {};
            }

            if (!speciesRawData[speciesName][xAxisName]) {
              speciesRawData[speciesName][xAxisName] = 0;
            }

            if (observationQuantity > 0) {
              speciesRawData[speciesName][xAxisName] += observationQuantity;
            }
          });
        });

        seasonNames = this.unique(seasonNames.sort());

        Object.keys(speciesRawData).forEach((speciesName) => {
          let colour = colours[speciesName];

          let speciesDatum = {
            backgroundColor:      `rgba(${colour},0.4)`,
            borderColor:          `rgba(${colour},0.7)`,
            hoverBackgroundColor: `rgba(${colour},0.9)`,
            hoverBorderColor:     `rgba(${colour},1.0)`,
            label:                 speciesName,
            type:                 'line',
            multiTooltipTemplate: '<%=value%> <%=datasetLabel%>',
            data:                 []
          };
          chartData.datasets.push(speciesDatum);

          seasonNames.forEach((seasonName, idx) => {
            chartData.labels[idx] = seasonName;

            speciesDatum.data[idx] = speciesRawData[speciesName][seasonName] || 0;
            speciesDatum.borderWidth = 2;
            speciesDatum.pointRadius = 2;
          });
        });

        this.chart = new Chart(canvas.getContext('2d'), {
          type:    'bar',
          data:    chartData,
          options: this.chartOptions
        });
      });
    } else {
      console.error('no <canvas> element found within <yearly-activity>!');
    }
  }

  get seasonElements() {
    return this.querySelectorAll('.site-observations-per-season')
  }

  unique(array) {
    return array.filter((el, index, arr) => {
      return index === arr.indexOf(el);
    });
  }
}

customElements.define('yearly-activity', YearlyActivity);
