class ShownOrHiddenIf extends HTMLElement {
  constructor() {
    super();
  }

  get radioList() {
    if (this.getAttribute('radio')) {
      return this.form.elements[this.getAttribute('radio')];
    } else {
      return undefined;
    }
  }

  get selectElement() {
    if (this.getAttribute('select')) {
      return this.form.querySelector(this.getAttribute('select'));
    } else {
      return undefined;
    }
  }

  connectedCallback() {
    window.setTimeout(this.connect.bind(this), 0);
  }

  connect() {
    if (!this.getAttribute('radio') && !this.getAttribute('select')) {
      console.error('please specify a radio="" or select="" attribute with the name of a radio button(-list) or select element');
    }
    this.form = this.closest('form');
    if (!this.form) {
      console.error('<hidden-if> only works within a <form>!');
      return;
    }
    this.classList.add('hidden-if');

    if (this.getAttribute('value')) {
      this.relevantValues = Array(this.getAttribute('value'));
    } else if (this.getAttribute('values')) {
      this.relevantValues = this.getAttribute('values').split(',');
    }

    if (this.selectElement) {
      this.registerEventListener(this.selectElement);
    } else if (this.radioList) {
      this.radioList.forEach(rb => { this.registerEventListener(rb) });
    } else {
//       console.error("neither select nor radio present");
      return false;
    }

    this.toggle();
  }

  registerEventListener(elem) {
    if (elem) elem.addEventListener('change', this.toggle.bind(this));
  }

  get selectedValue() {
    if (this.selectElement) {
      return this.selectElement.value;
    } else if (this.radioList) {
      return this.radioList.value;
    } else {
      console.error("neither select nor radio present");
    }
  }

  toggle() {
    if (this.showConditionMet) {
      this.show();
    } else {
      this.hide();
    }
  }

  show() {
    this.setAttribute('aria-hidden', false);
    this.removeAttribute('hidden');
    this.classList.remove('hidden-if-hidden');
  }

  hide() {
    this.setAttribute('aria-hidden', true);
    this.setAttribute('hidden', true);
    this.classList.remove('hidden-if-hidden');
  }
}

class ShownIf extends ShownOrHiddenIf {
  get showConditionMet() {
    return this.relevantValues.includes(this.selectedValue);
  }
}

class HiddenIf extends ShownOrHiddenIf {
  get showConditionMet() {
    return !this.relevantValues.includes(this.selectedValue);
  }
}

customElements.define('shown-if', ShownIf);
customElements.define('hidden-if', HiddenIf);
