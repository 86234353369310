import Chart from "chart.js/auto";

class ActivityChart extends HTMLElement {
  constructor() {
    super();

    this.datasetSelector = this.dataset.datasetSelector || 'tr.dataset'

    this.colours = [
      '51,102,204',
      '220,57,18',
      '255,153,0',
      '16,150,24',
      '153,0,153',
      '59,62,172',
      '0,153,198',
      '221,68,119',
      '102,170,0',
      '184,46,46',
      '49,99,149',
      '153,68,153',
      '34,170,153',
      '170,170,17',
      '102,51,204',
      '230,115,0',
      '139,7,7',
      '50,146,98',
      '85,116,166',
      '59,62,172'
    ];
  }

  connectedCallback() {
    window.setTimeout(this.connect.bind(this), 100);
  }

  connect() {
    const canvas = this.querySelector('canvas');

    if (canvas) {
      var chartCtx  = canvas.getContext('2d');
      var chartData = {
        labels: [],
        datasets: [{
          data: [],
          label: 'Aktivität',
          borderColor: `rgba(${this.colours[0]},0.9)`,
          fill: true,
          yAxisID: 'axisActivity',
        }, {
          label: 'ø-Temperatur',
          data: [],
          borderColor: `rgba(${this.colours[2]},0.9)`,
          fill: false,
          yAxisID: 'axisTemperature',
        }, {
          label: 'ø-Luftfeuchtigkeit',
          data: [],
          borderColor: `rgba(${this.colours[3]},0.9)`,
          fill: false,
          yAxisID: 'axisHumidity',
        }, {
          label: 'Vorhersage: min',
          data: [],
          borderColor: `rgba(100,100,100,0.5)`,
          fill: false,
          yAxisID: 'axisActivity',
        }, {
          label: 'Vorhersage: max',
          data: [],
          borderColor: `rgba(170,170,170,0.5)`,
          fill: false,
          yAxisID: 'axisActivity',
        }, {
          label: 'Vorhersage: Temperatur',
          data: [],
          borderColor: `rgba(${this.colours[2]},0.4)`,
          fill: false,
          yAxisID: 'axisTemperature',
        }, {
          label: 'Vorhersage: Luftfeuchtigkeit',
          data: [],
          borderColor: `rgba(${this.colours[3]},0.4)`,
          fill: false,
          yAxisID: 'axisHumidity',
        }]
      };
      Chart.defaults.plugins.legend = {
        display: false
      }

      let datasets = this.querySelectorAll(this.datasetSelector);
      datasets.forEach((dataset, n) => {
        let dateTd          = dataset.querySelector('.activity-date');
        let quantityTd      = dataset.querySelector('.activity-quantity');
        let temperatureTd   = dataset.querySelector('.activity-temperature');
        let humidityTd      = dataset.querySelector('.activity-humidity');
        let forecastMinTd   = dataset.querySelector('.activity-proj_min');
        let forecastMaxTd   = dataset.querySelector('.activity-proj_max');
        let FcTemperatureTd = dataset.querySelector('.activity-temperature-forecast');
        let FcHumidityTd    = dataset.querySelector('.activity-humidity-forecast');

        dataset.classList.add('hidden');

        quantityTd.style.backgroundColor = `rgba(${this.colours[0]},0.9)`;
        quantityTd.style.color           = '#fff';
        quantityTd.style.fontWeight      = 'bold';
        quantityTd.style.textAlign       = 'center';

        chartData.labels[n]           = dateTd.innerText;
        chartData.datasets[0].data[n] = parseInt(quantityTd.innerText);
        chartData.datasets[1].data[n] = parseFloat(temperatureTd.innerText);
        chartData.datasets[2].data[n] = parseFloat(humidityTd.innerText);
        chartData.datasets[3].data[n] = parseInt(forecastMinTd.innerText);
        chartData.datasets[4].data[n] = parseInt(forecastMaxTd.innerText);
        chartData.datasets[5].data[n] = parseFloat(FcTemperatureTd.innerText);
        chartData.datasets[6].data[n] = parseFloat(FcHumidityTd.innerText);
      });

      new Chart(chartCtx, {
        type: 'line',
        data: chartData,
        options: {
          spanGaps: true,
          animation: false,
          legend: {
            display: false
          },
          scales: {
            axisActivity: {
              type: 'linear',
              position: 'left',
              beginAtZero: true,
              title: {
                display: true,
                text: 'Aktivität',
                color: `rgba(${this.colours[0]},0.9)`
              },
              gridLines: {
                display: false,
              },
              ticks: {
                min: 0
              }
            },
            axisTemperature: {
              type: 'linear',
              position: 'right',
              title: {
                display: true,
                text: 'ø-Temperatur',
                color: `rgba(${this.colours[2]},0.9)`
              },
              gridLines: {
                display: true,
                zeroLineColor: 'rgba(100, 100, 240, 0.5)'
              },
              ticks: {
                max: 25,
                min: -10,
                callback: function(value) { return `${value}°C` }
              }
            },
            axisHumidity: {
              type: 'linear',
              position: 'right',
              title: {
                display: true,
                text: 'ø-Luftfeuchtigkeit',
                color: `rgba(${this.colours[3]},0.9)`
              },
              gridLines: {
                display: false
              },
              ticks: {
                max: 100,
                min: 0,
                callback: function(value) { return '' + value + '%' }
              }
            }/*, {
              id: 'axis-min-activity',
              type: 'linear',
              position: 'left',
              beginAtZero: true,
              scaleLabel: {
                display: true,
                text: 'Aktivität: min',
                color: "rgba(${this.colours[0]},0.9)"
              },
              gridLines: {
                display: false,
              },
              ticks: {
                min: 0
              }
            }, {
              id: 'axis-max-activity',
              type: 'linear',
              position: 'left',
              beginAtZero: true,
              scaleLabel: {
                display: true,
                text: 'Aktivität: max',
                color: "rgba(${this.colours[0]},0.9)"
              },
              gridLines: {
                display: false,
              },
              ticks: {
                min: 0
              }
            }*/
          }
        }
      });
    } else {
      console.log('no <canvas> found within <activity-chart>!');
    }

  }
}

customElements.define('activity-chart', ActivityChart);
